import { Flex, SideBySideLayout, Text, Button } from '@bonobolabs/mds-web-ui-reach'
import { DocumentTitle } from '../components/utils/DocumentTitle'

export const ErrorPage = () => {
  const onReloadClicked = () => {
    window.location.reload()
  }

  return (
    <>
      <DocumentTitle title="Something went wrong! | Flow by Moleskine" />
      <SideBySideLayout showFooter currentSite={null}>
        <Flex alignItems="center" flexDirection="column">
          <Text fontSize={5} mt={5} mb={3} color="grayapptitle">
            Something went wrong! We've been notified and will fix it as soon as possible.
          </Text>
          <Button color="black" type="primary" onClick={onReloadClicked} mt={3} uppercase>
            Reload the page
          </Button>
        </Flex>
      </SideBySideLayout>
    </>
  )
}
