import { theme as mdsTheme } from '@bonobolabs/mds-web-ui-reach'

const { colors, ...rest } = mdsTheme

export const GRAYSCALE = {
  gray1: '#181818',
  gray2: '#1b1b1b',
  gray3: '#282828',
  gray4: '#303030',
  gray5: '#373737',
  gray6: '#3f3f3f',
  gray7: '#4a4a4a',
  gray8: '#606060',
  gray9: '#6e6e6e',
  gray10: '#818181',
  gray11: '#b1b1b1',
  gray12: '#eeeeee'
}

export const theme = {
  ...rest,
  colors: {
    ...colors,
    ...GRAYSCALE,
    background: GRAYSCALE.gray1,
    foreground: GRAYSCALE.gray12
  }
}
